import { OnInit } from '@angular/core';
import { Router } from "@angular/router";
// import { RestService } from './../services/rest.service';
import { UserService } from './../services/user.service';
import { EventService } from './../services/event.service';
import { AnnouncementService } from './../services/announcement.service';
import { DocumentService } from './../services/document.service';
import { GoogleAnalyticsService } from './../services/analytics.service';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { environment } from './../../environments/environment';
var HomeComponent = /** @class */ (function () {
    function HomeComponent(user, events, msg, docs, router, 
    //public rest: RestService,
    ga) {
        this.user = user;
        this.events = events;
        this.msg = msg;
        this.docs = docs;
        this.router = router;
        this.ga = ga;
        this.userList = this.user.devUsers;
        this.showCal = false;
        this.isPTS = false;
        // data for demo
        this.latestPost = {};
        this.newsBtnText = "More News";
        this.displayMore = false;
        this.newsList = [];
        this.latestPosts = [];
        this.newsCount = 10;
        this.headlines = 3;
        this.linkList = [
            {
                'displayname': 'Product Information Management (PIM)',
                'desc': 'Set up and manage your products for sale on tractorsupply.com (formerly ATLAS)',
                'url': environment.pim,
                // 'desc' : 'This page is currently unavailable due to routine maintenance.', //maintenance page
                // 'url' : '/maintenance/index.html', // maintenance page
                'thumbnail': '',
                'order': '1',
                'level': '',
                'show': 'TSC',
                'contact': 'atlas@tractorsupply.com'
            },
            {
                'displayname': 'SKU Portal',
                'desc': 'Submit Test SKUs for EDI Setup',
                'url': environment.skuportal,
                // 'desc' : 'This page is currently unavailable due to routine maintenance.', //maintenance page
                // 'url' : '/maintenance/index.html', // maintenance page
                'thumbnail': '',
                'order': '2',
                'level': '',
                'show': '',
                'contact': 'ProductData@tractorsupply.com'
            },
            {
                'displayname': 'Vendor Information System (VIS)',
                'desc': 'Custom reporting from the Vendor Information System',
                'url': 'https://vis4app.tractorsupply.com',
                'thumbnail': '',
                'order': '3',
                'level': '',
                'show': '',
                'contact': 'VISSupport@tractorsupply.com'
            },
            {
                'displayname': 'Retail Media | Campaign Management Portal',
                'desc': 'A one-stop repository for contracts/insertion Orders, billing, files, and asset/creative file management for your Retail Media Network campaigns.',
                'url': 'https://tscvendorfusion.themarsagency.com/',
                'thumbnail': '',
                'order': '6',
                'level': '',
                'show': '',
                'contact': 'TractorSupplyMediaNetwork@TractorSupply.com'
            },
            {
                'displayname': 'Vendor Compliance',
                'desc': 'Learn more about the ways Tractor Supply Co. strives to outperform the competition',
                'url': 'https://tsco.traversesystems.com/#/login ',
                'thumbnail': '',
                'order': '5',
                'level': '',
                'show': 'TSC',
                'contact': 'TSCCOMPLIANCE@tractorsupply.com'
            },
            {
                'displayname': 'Vendor Power BI Dashboards',
                'desc': 'Dashboards that TSC has made available for Vendors are available on the link.<br /><br />Questions? Applicable contact emails are listed on each individual dashboard.',
                'url': 'campaign-performance',
                'thumbnail': '',
                'order': '6',
                'level': '',
                'show': '',
                'contact': ''
            },
            {
                'displayname': 'Accounts Payable (AP) Portal',
                'desc': 'Search for invoices or payments',
                'url': 'https://tsc.apexanalytix.com/',
                'thumbnail': '',
                'order': '4',
                'level': 'Accounting',
                'show': '',
                'contact': 'AskAP@tractorsupply.com'
            },
            {
                'displayname': 'Vendor Transaction Portal (VTP)',
                'desc': 'Transaction Error Application<br />Find Invoice and ASN (DC Only) Transaction Errors<br />The ASN is not required for direct to store shipments.',
                'url': 'https://vtp.tractorsupply.com',
                'thumbnail': '',
                'order': '7',
                'level': '',
                'show': '',
                'contact': 'EDI@TractorSupply.com'
            },
            {
                'displayname': 'VTP Shipment Delay Application',
                'desc': 'Update delayed shipment information.',
                'url': 'https://vtp.tractorsupply.com',
                'thumbnail': '',
                'order': '8',
                'level': '',
                'show': '',
                'contact': 'digitalbizopsordermanagement@tractorsupply.com' // NSC-331 change from 'DropShipMC@TractorSupply.com'
            },
            {
                'displayname': 'Ariba Network Supplier Portal',
                'desc': 'Manage your Ariba Network Supplier Account',
                'url': 'https://service.ariba.com/Supplier.aw/125032058/aw?awh=r&awssk=Uh6e4e_0&dard=1',
                'thumbnail': '',
                'order': '9',
                'level': '',
                'show': 'TSC',
                'contact': 'ProcureToPay@tractorsupply.com'
            },
            {
                'displayname': 'Manhattan TLM',
                'desc': 'Transportation Portal',
                'url': 'https://mip-transportation.tractorsupply.com/login.jsp ',
                'thumbnail': '',
                'order': '9',
                'level': '',
                'show': 'TSC',
                'contact': 'TSPSupport@tractorsupply.com'
            },
            {
                'displayname': 'Expeditors Booking Tool',
                'desc': 'Booking Import Freight',
                'url': 'https://omb.expeditors.com/omb/index.jsp ',
                'thumbnail': '',
                'order': '9',
                'level': '',
                'show': 'TSC',
                'contact': 'imports_transportation@tractorsupply.com'
            },
            {
                'displayname': 'Cleartrack',
                'desc': 'Import Quote Management',
                'url': 'https://www.cleartrack.com/content/login.jsp ',
                'thumbnail': '',
                'order': '10',
                'level': '',
                'show': 'TSC',
                'contact': 'imports@tractorsupply.com'
            }
        ];
        this.modalContent = {
            'header': '',
            'body': '',
            'footer': ''
        };
    }
    HomeComponent.prototype.ngOnInit = function () {
        if (this.newsList && this.newsList.length > 0) {
            this.latestPost = this.newsList[0];
        }
        // apply sort order to links
        this.linkList = this.linkList.sort(function (a, b) { return a.order - b.order; });
        this.refreshEvents();
        this.refreshNews();
        console.log('User information', this.user.loggedInUser);
        if (this.isGuest()) {
            console.log('Redirecting guest user', this.isGuest());
            this.router.navigate(['consent']);
        }
    };
    HomeComponent.prototype.showMoreNews = function () {
        return this.displayMore;
    };
    HomeComponent.prototype.toggleMoreNews = function () {
        if (this.displayMore) {
            this.displayMore = false;
            this.newsBtnText = "More News";
        }
        else {
            this.displayMore = true;
            this.newsBtnText = "Hide List";
        }
    };
    HomeComponent.prototype.refreshEvents = function () {
        var _this = this;
        // get latest event list
        this.events.retrieveEvents().subscribe(function (data) {
            _this.showCal = true;
        }, function (error) {
            console.log('there was an error', error);
            _this.showCal = true;
        });
    };
    HomeComponent.prototype.refreshNews = function () {
        var _this = this;
        this.latestPost = null;
        this.latestPosts = [];
        this.newsList = [];
        this.docs.selectedCategory = '';
        console.log('reset category', this.docs.selectedCategory);
        return this.msg.retrieveAnnouncements().subscribe(function (data) {
            // process by pubdate and user (user should be in request)
            var now = moment().format();
            var msglist = data.filter(function (a) { return moment(a.publishDate).isSameOrBefore(now) && (!a.expiryDate || moment(a.expiryDate).isSameOrAfter(now)); });
            console.log('removed expired', msglist);
            var role = _this.user.getUserRole();
            console.log('User Role: ' + role, _this.user.loggedInUser);
            // petsense
            if (_this.user.loggedInUser && _this.user.loggedInUser.vendorType && _this.user.loggedInUser.vendorType.indexOf('PTS') > -1) {
                _this.isPTS = true;
            }
            else {
                _this.isPTS = false;
            }
            // first, filter by lob and buyer id
            // TIBCO-3596 Audience Filters
            var currUser = _this.user.loggedInUser;
            // filter by vendor list, lob, or buyer id
            if (!role || (role && role.toString().toUpperCase() !== 'TSCADMIN')) {
                // start with announcements with no filters, visible to all
                var combinedAnn = msglist.filter(function (a) { return (!a.lob || a.lob.length == 0) && (!a.buyerId || a.buyerId.length == 0) && (!a.vendor || a.vendor.length == 0); });
                var ann = [];
                console.log('LOB', currUser.lob);
                console.log('VendorList', currUser.vendorList);
                console.log('BuyerID', currUser.buyerId);
                console.log('general announcements', combinedAnn);
                if (currUser && currUser.lob) {
                    // LOB first
                    var lob = [];
                    if (currUser.lob.indexOf(',') > -1) {
                        // parse comma separated list for user
                        lob = currUser.lob.split(',');
                    }
                    else {
                        // lob.push(currUser.lob);
                        // service returns array
                        lob = currUser.lob;
                    }
                    var _loop_1 = function (l) {
                        var lobAnn = msglist.filter(function (a) { return a.lobArr && a.lobArr.indexOf(l) > -1; });
                        ann = ann.concat(lobAnn);
                        console.log('announcements for LOB: ' + l, ann);
                    };
                    for (var _i = 0, lob_1 = lob; _i < lob_1.length; _i++) {
                        var l = lob_1[_i];
                        _loop_1(l);
                    }
                }
                if (currUser && currUser.vendorList) {
                    // Vendor List
                    var vids = [];
                    /* // old vendor number list
                    if (currUser.vendorNumber.indexOf(',') > -1) {
                      vids = currUser.vendorNumber.split(',');
                    } else {
                      vids.push(currUser.vendorNumber);
                    } */
                    for (var _a = 0, _b = currUser.vendorList; _a < _b.length; _a++) {
                        var vendor = _b[_a];
                        if (vendor && vendor.VendorID && vendor.VendorID !== '') {
                            vids.push(vendor.VendorID);
                        }
                    }
                    console.log('user vendor ids', vids);
                    var _loop_2 = function (v) {
                        var vendorAnn = msglist.filter(function (a) { return a.vendorIds && a.vendorIds.indexOf(v) > -1; });
                        ann = ann.concat(vendorAnn);
                        console.log('announcements for VendorID: ' + v, ann);
                    };
                    for (var _c = 0, vids_1 = vids; _c < vids_1.length; _c++) {
                        var v = vids_1[_c];
                        _loop_2(v);
                    }
                }
                if (currUser && currUser.buyerId) {
                    // Buyer ID
                    var bids = [];
                    if (currUser.buyerId.indexOf(',') > -1) {
                        bids = currUser.buyerId.split(',');
                    }
                    else {
                        // bids.push(currUser.buyerId);
                        bids = currUser.buyerId;
                    }
                    var _loop_3 = function (b) {
                        var buyerAnn = msglist.filter(function (a) { return a.buyerArr && a.buyerArr.indexOf(b) > -1; });
                        ann = ann.concat(buyerAnn);
                        console.log('announcements for BuyerID: ' + b, ann);
                    };
                    for (var _d = 0, bids_1 = bids; _d < bids_1.length; _d++) {
                        var b = bids_1[_d];
                        _loop_3(b);
                    }
                }
                var _loop_4 = function (a) {
                    if (a && !combinedAnn.find(function (m) { return m.id === a.id; })) {
                        // if it's not already in the list, add it
                        combinedAnn.push(a);
                    }
                };
                // add user posts to public list
                for (var _e = 0, ann_1 = ann; _e < ann_1.length; _e++) {
                    var a = ann_1[_e];
                    _loop_4(a);
                }
                console.log('filtered Announcements', combinedAnn);
                msglist = combinedAnn;
                // end new filters
                //   if (role && role.toString().toUpperCase() === 'TSCADMIN'){
                // show all posts
                //   } else if (role && role.toString().toUpperCase() === 'VENDORADMIN'){
                if (role && role.toString().toUpperCase() === 'VENDORADMIN') {
                    msglist = msglist.filter(function (m) { return m.roleName && m.roleName.indexOf('TSCAdmin') < 0; }); // show all except TSCAdmin
                    // msglist = msglist.filter(m => m.roleName!=='TSCAdmin' && m.roleName.indexOf('VendorAdmin') > -1 || (!m.roleName || m.roleName === '' || m.roleName === 'null' || m.roleName.toLowerCase() === 'all' || m.roleName.indexOf('Vendor') > -1));
                    console.log('vendor admin list', msglist);
                }
                else {
                    // generic vendor announcements
                    var vendorMsg = msglist.filter(function (m) { return m.roleName !== 'VendorAdmin' && m.roleName !== 'TSCAdmin' && m.roleName !== 'TSC_Buyer' && m.roleName !== 'PTS_Buyer' && (!m.roleName || m.roleName === '' || m.roleName === 'null' || m.roleName.toLowerCase() === 'all' || m.roleName === 'Vendor'); });
                    // handle multiple roles
                    var uRoles = role;
                    if (role && role.indexOf(',') > -1) {
                        // console.log('user role contains commas, split it!');
                        uRoles = role.split(/\s*,\s*/g);
                        var _loop_5 = function (r) {
                            var targetedNews = msglist.filter(function (m) { return m.roleName.indexOf(r) > -1; });
                            console.log('announcements for ' + r, targetedNews);
                            var _loop_8 = function (a) {
                                var existing = vendorMsg.find(function (n) { return n.id === a.id; }); // remove duplicates
                                if (!existing) {
                                    vendorMsg.push(a);
                                }
                            };
                            for (var _i = 0, targetedNews_2 = targetedNews; _i < targetedNews_2.length; _i++) {
                                var a = targetedNews_2[_i];
                                _loop_8(a);
                            }
                        };
                        for (var _f = 0, uRoles_1 = uRoles; _f < uRoles_1.length; _f++) {
                            var r = uRoles_1[_f];
                            _loop_5(r);
                        }
                    }
                    else {
                        // handle single role
                        var r_1 = role;
                        var targetedNews = msglist.filter(function (m) { return m.roleName.indexOf(r_1) > -1; });
                        console.log('announcements for ' + r_1, targetedNews);
                        var _loop_6 = function (a) {
                            var existing = vendorMsg.find(function (n) { return n.id === a.id; }); // remove duplicates
                            if (!existing) {
                                vendorMsg.push(a);
                            }
                        };
                        for (var _g = 0, targetedNews_1 = targetedNews; _g < targetedNews_1.length; _g++) {
                            var a = targetedNews_1[_g];
                            _loop_6(a);
                        }
                    }
                    // || m.roleName.indexOf(role) > -1
                    // replace msglist with list for role
                    if (!role || (role && role.toString().toUpperCase() !== 'TSCADMIN' && role.toString().toUpperCase() !== 'VENDORADMIN')) {
                        msglist = vendorMsg;
                    }
                }
                // filter by vendor type
                console.log('filter by vendor type', currUser.vendorType);
                var currentVendorTypes_1 = [];
                if (currUser.vendorType && currUser.vendorType !== '') {
                    // vendorType may be comma separated, force to array
                    if (currUser.vendorType.indexOf(',') > -1) {
                        // add unique
                        var vt = currUser.vendorType.split(',');
                        if (vt && vt.length > 0) {
                            var _loop_7 = function (t) {
                                var dupType = currentVendorTypes_1.find(function (v) { return v == t; });
                                if (!dupType) {
                                    currentVendorTypes_1.push(t);
                                }
                            };
                            for (var _h = 0, vt_1 = vt; _h < vt_1.length; _h++) {
                                var t = vt_1[_h];
                                _loop_7(t);
                            }
                        }
                    }
                    else {
                        currentVendorTypes_1.push(currUser.vendorType);
                    }
                    console.log('Vendor Type', currentVendorTypes_1);
                    if (currentVendorTypes_1.length > 0) {
                        msglist = msglist.filter(function (t) { return !t.vendorType || t.vendorType == '' || t.vendorType == 'all' || _this.findVendorType(t.vendorType, currentVendorTypes_1); });
                    }
                }
                //if (currUser.vendorType && currUser.vendorType !== '') {
                //  msglist = msglist.filter(t => !t.vendorType || t.vendorType == '' ||  t.vendorType.indexOf(currUser.vendorType)>-1);
                // }
                // filter by vendor attributes, in currUser.vendorList
                console.log('filter by vendor list', currUser.vendorList);
                if (currUser.vendorList) {
                    // msglist = msglist.filter(a => !a.vendorAttr || a.vendorAttr == '' ||  a.vendorAttr.indexOf(currUser.vendorAttributes)>-1);
                    msglist = msglist.filter(function (a) { return !a.vendorAttr || a.vendorAttr == '' || a.vendorAttr == 'all' || _this.showVendorAttr(a); });
                }
                else {
                    msglist = msglist.filter(function (a) { return !a.vendorAttr || a.vendorAttr == '' || a.vendorAttr == 'all'; });
                }
            }
            // sort announcements by date
            msglist = _this.user.sortByDate(msglist, 'publishDate').reverse();
            console.log('announcements for this user', msglist);
            // get most recent 10 (newsCount)
            for (var m = 0; m < _this.newsCount; m++) {
                if (msglist[m]) {
                    if (m > _this.headlines - 1) {
                        _this.newsList.push(msglist[m]);
                    }
                    else {
                        _this.latestPosts.push(msglist[m]);
                    }
                }
            }
            // this.latestPost = this.newsList[0];
        });
    };
    HomeComponent.prototype.findVendorType = function (csvField, typesArr) {
        for (var _i = 0, typesArr_1 = typesArr; _i < typesArr_1.length; _i++) {
            var t = typesArr_1[_i];
            if (csvField.indexOf(t) > -1) {
                return true;
            }
        }
        return false;
    };
    HomeComponent.prototype.visitLink = function (link) {
        if (link.url && link.url.indexOf('http') < 0) {
            // handle relative page links
            this.router.navigate([link.url]);
        }
        else {
            // ga tracking
            console.log('outbound link: ', link);
            this.ga.track('/VendorPortal/outbound/' + link.displayname);
            // redirect
            window.location.href = link.url;
        }
    };
    HomeComponent.prototype.goToALink = function (msg) {
        console.log(msg);
        if (msg.announceLink && msg.announceLink !== '') {
            console.log('visiting ' + msg.announceLink);
            var aLink = document.createElement('a');
            aLink.target = '_blank';
            aLink.href = msg.announceLink;
            aLink.setAttribute('visibility', 'hidden');
            aLink.click();
        }
    };
    HomeComponent.prototype.viewAttachment = function (file, modal) {
        var _this = this;
        // access download function
        console.log(file);
        var downloadPath = file.docPath;
        this.docs.downloadDocument(downloadPath, true).subscribe(function (data) {
            console.log('downloading attachment from ' + file.id);
            saveAs(data, file.fileName);
            _this.ga.track('/VendorPortal/attachment/download/' + file.fileName);
        }, function (error) {
            console.log(error);
            _this.modalContent.header = 'Error';
            _this.modalContent.body = 'There was a problem downloading the document.';
            _this.modalContent.footer = error.text();
            _this.ga.track('/VendorPortal/error/download/' + file.fileName);
            modal.show();
        });
    };
    HomeComponent.prototype.showVendorAttr = function (msg) {
        // include this announcement/event in the list
        var showMe = true;
        var vendorsWithAttributes = this.user.loggedInUser.vendorList.find(function (v) { return v.VendorAttribute && v.VendorAttribute !== ''; });
        if (msg.vendor === '' || msg.vendorIds.length === 0) {
            // no vendor ID, check for any attribute in vendorList
            // if announcement/event has any Vendor Attribute, only show to those vendors
            console.log('message attributes?', msg.vendorAttr, msg.vendorAttrArr);
            if (msg.vendorAttrArr && msg.vendorAttrArr.length > 0) {
                showMe = false;
                if (vendorsWithAttributes) {
                    for (var _i = 0, _a = msg.vendorAttrArr; _i < _a.length; _i++) {
                        var attr = _a[_i];
                        for (var _b = 0, vendorsWithAttributes_1 = vendorsWithAttributes; _b < vendorsWithAttributes_1.length; _b++) {
                            var vnum = vendorsWithAttributes_1[_b];
                            console.log('looking for ', attr, vnum);
                            if (vnum.VendorAttribute.indexOf(attr) > -1) {
                                showMe = true;
                                console.log('attribute found, show', vnum.VendorAttribute, attr, msg);
                            }
                        }
                    }
                }
            }
        }
        else {
            // message assigned to vendor id, check if attribute is also assigned
            console.log('message attributes?', msg.vendorAttr, msg.vendorAttrArr);
            if (msg.vendorAttrArr && msg.vendorAttrArr.length > 0) {
                showMe = false;
                if (vendorsWithAttributes) {
                    var _loop_9 = function (v) {
                        var thisVendor = vendorsWithAttributes.find(function (x) { return v.VendorID === v; });
                        if (thisVendor && thisVendor.VendorAttributes) {
                            for (var _i = 0, _a = msg.vendorAttrArr; _i < _a.length; _i++) {
                                var attr = _a[_i];
                                console.log('looking for ', attr, thisVendor.VendorAttributes);
                                if (thisVendor.VendorAttributes.indexOf(attr) > -1) {
                                    showMe = true;
                                    console.log('attribute found for ' + v, attr, thisVendor, msg);
                                }
                            }
                        }
                    };
                    for (var _c = 0, _d = msg.vendorIds; _c < _d.length; _c++) {
                        var v = _d[_c];
                        _loop_9(v);
                    }
                }
            }
        }
        console.log(showMe, msg);
        return showMe;
    };
    HomeComponent.prototype.isGuest = function () {
        return this.user.getUserRole() === "Guest";
    };
    // demo functions
    HomeComponent.prototype.switchUser = function (id) {
        this.user.setUser(id);
        this.refreshNews();
        this.refreshEvents();
    };
    HomeComponent.prototype.switchBG = function () {
        var div = document.querySelector('.section-splash');
        if (div.classList.contains('altimg')) {
            div.classList.remove('altimg');
        }
        else {
            div.classList.add('altimg');
        }
    };
    HomeComponent.prototype.selectDocumentCategory = function (cat) {
        this.docs.selectedCategory = cat;
    };
    return HomeComponent;
}());
export { HomeComponent };
